import { avatar, avatar2, bot, left, tick } from "assets/media/images";
import React, { useState,useEffect } from "react";
import "./ChatHistoryList.scss";
import { useSelector, useDispatch } from 'react-redux';
import { agentChatHistoryActions } from '../../redux/agent-chat-history/agent-chat-history.slice';
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { IChatHistory, IChatHistoryList } from "modules/gpo-agent/models/chat-history/chat-history-list.model";
import { IChatHistoryMessage, IChatHistoryMessages } from "modules/gpo-agent/models/chat-history/chat-history-messages.model";
import { ChatMessageType } from "modules/gpo-agent/enums/chat-message-types.enum";
import { ChatMessages } from "modules/gpo-agent/enums/chat-messages.enum";
import moment from "moment";
import { select } from "redux-saga/effects";
import Loader from "common/components/base/Loader/Loader";
import ProfileImage from "common/components/custom/ProfileImage/ProfileImage";
import AnnotateDetails from "../AnnotateDetails/AnnotateDetails"
import HighlightText from "../HighlightText/HighlightText";
import { TabType } from "common/enums/tab-types.enum";

const ChatHistoryList = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [chatHistoryMessages, setChatHistoryMessages] = useState<Array<IChatHistoryMessage>>([]);
  const [selectedChat, setSelectedChat] = useState<IChatHistory>();
  const [loading, setLoading] = useState<boolean>(false);
  const [annotateView, setAnnotateView] = useState<boolean>(true);
  const [annotateDetailView, setAnnotateDetailView] = useState<number>(-1);
  const [searchText, setSearchText] = useState<any>("");

  const chatHistoryDispatch = useAppDispatch();
  const chatHistoryState = useAppSelector((state: RootState) => state.agentChatHistory);

  useEffect(()=>{   
    if(!chatHistoryState?.chatHistoryList || !chatHistoryState?.chatHistoryList?.chatHistory?.length){
        setLoading(false);
    }
   else{
      setLoading(true);
   }
  },[chatHistoryState?.chatHistoryList])

  useEffect(() => {
    if(chatHistoryState?.selectedChatHistory){
      const selectedChat:IChatHistory = chatHistoryState?.selectedChatHistory;
      const handsOffGuid:string = selectedChat?.handoffGuid;
      if(handsOffGuid){
        setSelectedChat(selectedChat);
        setChatHistoryMessages([]);
        setLoading(true);
        chatHistoryDispatch(agentChatHistoryActions.getChatHistoryMessages(handsOffGuid)); 
      }

    }
  }, [chatHistoryState?.selectedChatHistory]);

  useEffect(() => {
    const chatHistoryUsersListResponse:IChatHistoryMessages|undefined = chatHistoryState?.chatHistoryMessages;
    if(!chatHistoryUsersListResponse){
      setChatHistoryMessages([]);
      setLoading(true);
    }
    if(chatHistoryUsersListResponse?.isSuccess){
      const chatMessages:IChatHistoryMessage[] = chatHistoryUsersListResponse?.chatMessages;
      if(chatMessages){
        setChatHistoryMessages(chatMessages);
        setLoading(false);
      }
    }
    
  },[chatHistoryState?.chatHistoryMessages]);
  
  useEffect(()=>{
    const element = document.getElementsByClassName('tab-content')[0];
    element.scroll({ top: element.scrollHeight});
  },[loading])

  useEffect(()=>{
    setAnnotateView(chatHistoryState?.isAnnotateView);
    handleTab("tab1");
  },[chatHistoryState?.isAnnotateView])

  useEffect(()=>{
    if(chatHistoryState?.annotateDetailsResponse){
      hideAnnotateDetails();
      if(chatHistoryState?.annotateDetailsResponse?.isSuccess){
      const handsOffGuid:any = selectedChat?.handoffGuid;
      if(handsOffGuid){
       // chatHistoryDispatch(agentChatHistoryActions.clearChatHistoryMessages()); 
        chatHistoryDispatch(agentChatHistoryActions.getChatHistoryMessages(handsOffGuid)); 
      }
    } 
    }
  },[chatHistoryState?.annotateDetailsResponse])

  useEffect(()=>{
    if(chatHistoryState?.completeAnnotationResponse && chatHistoryState?.completeAnnotationResponse?.isSuccess){
      const handsOffGuid:any = selectedChat?.handoffGuid;
      if(handsOffGuid){
        chatHistoryDispatch(agentChatHistoryActions?.getChatHistoryList(""));
        chatHistoryDispatch(agentChatHistoryActions?.updateChatAnnotateView(false));
        chatHistoryDispatch(agentChatHistoryActions.updateSelectedChatHandOffGuid(handsOffGuid));
        handleTab("tab1");
      } 
    }
  },[chatHistoryState?.completeAnnotationResponse])
  
  useEffect(()=>{   
    setSearchText(chatHistoryState?.searchMessagesText);
  },[chatHistoryState?.searchMessagesText])

  const handleTab = (tab:string) => {
    setActiveTab(tab);
  };

  const showAnnotateDetails = (event:any,message:IChatHistoryMessage,index:number) =>{
    let annotateButton =  event?.currentTarget;
    if(annotateButton){
      annotateButton?.classList?.add("hide");
    }
    setAnnotateDetailView(index)
  }
  const hideAnnotateDetails = () =>{
    const hiddenAnnotateButtons:any = document.getElementsByClassName("annotate hide");
    if(hiddenAnnotateButtons){
      for(let button of hiddenAnnotateButtons && hiddenAnnotateButtons){
        button?.classList?.remove("hide");
      }
    }
    setAnnotateDetailView(-1);
  }

  const changeView =  (isAnnotateView:boolean) =>{
    chatHistoryDispatch(agentChatHistoryActions?.updateChatAnnotateView(isAnnotateView));
  }

  const completeAnnotation = () =>{
    const handsoffGuid:any = selectedChat?.handoffGuid;
    if(handsoffGuid){
    chatHistoryDispatch(agentChatHistoryActions?.completeAnnotation(handsoffGuid));
    }
  }

  return (
    <div className="chat-history-list">
      <div className="chat-history-list__container">
        {chatHistoryState?.chatHistoryList && !annotateView && selectedChat && selectedChat?.displayName && (
        <header>
          <div className="left-chat">
          <ProfileImage profileId={selectedChat?.guid} />
            <span><strong>{selectedChat?.displayName}</strong></span>
         </div>
         </header>
        )}
        {chatHistoryState?.chatHistoryList && annotateView && (
         <header>
          <div className="left">
            <h3>Annotate</h3>
            <div className="tabs">
              <span
                className={activeTab === "tab1" ? "active" : ""}
                onClick={(event) => handleTab("tab1")}
              >
                Whole Chat
              </span>
              <span
                className={activeTab === "tab2" ? "active" : ""}
                onClick={(event) => handleTab("tab2")}
              >
                My Replies
              </span>
            </div>
          </div>
          <div className="right">
            <button  onClick={(event) => changeView(false)}>Cancel</button>
            <button  onClick={(event) => completeAnnotation()}>Done</button>
          </div> 
         </header>
        )}
        <div className="tab-content">
          {activeTab === "tab1" ? (
              <div className="whole-chat">
                {!!loading && <Loader/>}
                  {!!chatHistoryMessages && !!chatHistoryMessages?.length && (
                  <div className={chatHistoryMessages?.length<=5?"whole-chat__container shortChat":"whole-chat__container"}>
                    {chatHistoryMessages?.map((message:IChatHistoryMessage, i) => 
                     <React.Fragment key={message?.messageGuid || i}>
                      {message?.messageType==ChatMessageType.BOTMESSAGE && (
                        <section className="bot">
                        <header>
                          <img src={bot} />
                          <div className="name">
                            <strong>Mobility Chatbot</strong>
                            <span>{moment.utc(message?.messageCreatedDate)?.local().fromNow()}</span>
                          </div>
                        </header>
                        {/* <p>{message?.messageText}</p> */}
                        <HighlightText
                          searchText={searchText}
                          displayText={message?.messageText?.replace(/\n/g, "</br>")}
                        />
                      </section>
                     )}
                     {message?.messageType==ChatMessageType.CLIENTMESSAGE && (
                        <section className="user">
                        <header>
                          <ProfileImage profileId={message?.user?.guid} />
                          <div className="name">
                            <strong>{message?.user?.displayName}</strong>
                            <span>{moment.utc(message?.messageCreatedDate)?.local().fromNow()}</span>
                          </div>
                        </header>
                        {/* <p>{message?.messageText}</p> */}
                        <HighlightText
                          searchText={searchText}
                          displayText={message?.messageText?.replace(/\n/g, "</br>")}
                        />
                        {/* <footer>
                          <button>Yes</button>
                          <button>No</button>
                        </footer> */}
                      </section>
                     )}
                     {message?.messageType==ChatMessageType.EVENTMESSAGE && message?.messageText==ChatMessages.AGENT_JOINED &&( 
                       <section className="new-agent">
                          <div className="detail">
                              <ProfileImage profileId={message?.user?.guid} />
                              <span>
                                <strong>{message?.user?.displayName}</strong> joined the chat
                              </span>
                              <span>{moment.utc(message?.messageCreatedDate)?.local().fromNow()}</span>
                          </div>
                        </section> 
                      )}
                      {message?.messageType==ChatMessageType.AGENTMESSAGE && (
                        <>
                        <section className="agent">
                        <header>
                          <div className="name">
                            <strong>You</strong>
                            <span>{moment.utc(message?.messageCreatedDate)?.local().fromNow()}</span>
                          </div>
                        </header>
                        {/* <p>{message?.messageText}</p> */}
                        <HighlightText
                          searchText={searchText}
                          displayText={message?.messageText?.replace(/\n/g, "</br>")}
                        />
                        {!message?.isAnnotated && annotateView &&(
                          <span className="annotate" onClick={(event) => showAnnotateDetails(event,message,i)}>Annotate</span>
                        )}
                        {message?.isAnnotated && annotateView &&(
                          <span className="annotated"><img src={tick}/> Annotated</span>
                        )}                    
                      </section>
                      {!message?.isAnnotated && annotateDetailView==i &&(
                         <section>
                         <AnnotateDetails
                          messageGuid={message?.messageGuid}
                          handOffGuid={selectedChat?.handoffGuid}
                          userGuid={message?.user?.guid}
                          onAnnotateDetailsClose={hideAnnotateDetails}
                         />
                        </section>
                       )}                      
                      </>
                     )}
                     {message?.messageType==ChatMessageType.EVENTMESSAGE && message?.messageText==ChatMessages.AGENT_LEFT &&(  
                         <section className="agent-left">
                           <div className="center">
                             <img src={left} />
                             <span>
                               <strong>{message?.user?.displayName}</strong> left the chat
                             </span>
                          </div>
                         </section>
                      )}
                  </React.Fragment>
                )}   
                
                {selectedChat && !selectedChat?.isAnnotated && !annotateView && (
                   <section className="oops">
                   <div className="container">
                     <div>
                       <h4>Oops!</h4>
                       <span>
                         Looks like annotations are pending. Do you have a few
                         minutes to spare for training the AI regarding the
                         annotations
                       </span>
                     </div>
                     <button  onClick={(event) => changeView(true)}>Start</button>
                   </div>
                 </section>
                )}  
              </div>
                )}
                {(!chatHistoryState?.chatHistoryList || !chatHistoryState?.chatHistoryList?.chatHistory?.length) && (
                  <div className="no-data">
                     <span>No Chat History Found!</span>
                  </div>               
                )}
            </div>
          ) : (
            <div className="replies">
                {loading && <Loader/>}
                  {chatHistoryMessages && chatHistoryMessages?.length && (
                  <div className="whole-chat__container">
                    {chatHistoryMessages?.filter((message:IChatHistoryMessage) => message.messageType===ChatMessageType.AGENTMESSAGE)
                     .map((message:IChatHistoryMessage, i) =>  
                     <React.Fragment key={message?.messageGuid || i}>
                      {message?.messageType==ChatMessageType.AGENTMESSAGE && (
                        <>
                        <section className="agent">
                        <header>
                          <div className="name">
                            <strong>You</strong>
                            <span>{moment.utc(message?.messageCreatedDate)?.local().fromNow()}</span>
                          </div>
                        </header>
                        {/* <p>{message?.messageText}</p> */}
                        <HighlightText
                          searchText={searchText}
                          displayText={message?.messageText?.replace(/\n/g, "</br>")}
                        />
                        {!message?.isAnnotated && annotateView && (
                          <span className="annotate" onClick={(event) => showAnnotateDetails(event,message,i)}>Annnotate</span>
                        )}
                        {message?.isAnnotated && annotateView &&(
                          <span className="annotated"><img src={tick}/> Annnotated</span>
                        )}                    
                      </section>
                      {!message?.isAnnotated && annotateDetailView==i &&(
                         <section>
                         <AnnotateDetails
                           messageGuid={message?.messageGuid}
                           handOffGuid={selectedChat?.handoffGuid}
                           userGuid={message?.user?.guid}
                           onAnnotateDetailsClose={hideAnnotateDetails}
                         />
                        </section>
                       )}
                      </>
                     )}
                  </React.Fragment>
                )}   
                
                {/* <section className="agent-left">
                  <div className="center">
                    <img src={left} />
                    <span>
                      <strong>John Doe</strong> left the chat
                    </span>
                  </div>
                </section> */} 
              </div>
                )}
                {(!chatHistoryState?.chatHistoryList || !chatHistoryState?.chatHistoryList?.chatHistory?.length) && (
                  <div className="no-data">
                     <span>No Chat History Found!</span>
                  </div>               
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatHistoryList;
