import { logo } from "assets/media/images";

import "./Logout.scss";

const Logout = () => {
    
    const hadleLogin = () => {
      window.location.reload();
    }
  return (
    <div className="logout-wrapper">
      <div className="logout-wrapper__container">
        <div className="card">
          <header>
            <img src={logo} />
            <span>
               <strong>Mobility Chatbot</strong>
            </span>
          </header>
          <p>You have successfully Logged out !!!</p>
          <button type="button" onClick={hadleLogin}>LOGIN</button>
        </div>
      </div>
    </div>
  );
};

export default Logout;
